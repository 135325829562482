import { InfoWindow, Map, Marker, useMap } from "@vis.gl/react-google-maps"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export type CentruDePlata = {
  id: number
  idZona: number
  procesatorPlati: "PayPoint" | "SelfPay" | "Un-doi"
  denumireCentru: string
  adresa: string
  latLng: [number, number]
  programLucru: string | null
}

export function getCLItemClassNameFromTipProcesator(item: CentruDePlata) {
  switch (item.procesatorPlati) {
    case "SelfPay":
      return "cdpLCItemSelfpay"
    case "PayPoint":
      return "cdpLCItemPaypoint"
    case "Un-doi":
      return "cdpLCItemUnDoi"
    default:
      return ""
  }
}

export function getCLIIconClassNameFromTipProcesator(item: CentruDePlata) {
  switch (item.procesatorPlati) {
    case "SelfPay":
      return (
        <StaticImage
          alt="Selfpay centru de plati logo"
          src="../../images/selfpay_logo.png"
          className="cdpLogoSelfPay"
        />
      )
    case "PayPoint":
      return (
        <StaticImage
          alt="PayPoint centru de plati logo"
          src="../../images/payPoint_logo.png"
          className="cdpLogoPayPoint"
          loading="eager"
        />
      )
    case "Un-doi":
      return (
        <StaticImage
          alt="Un-doi centru de plati logo"
          src="../../images/un-doi_logo.png"
          className="cdpLogoUnDoi"
        />
      )
    default:
      return null
  }
}

export function getMarkerIconColorFromTipProcesator(item: CentruDePlata) {
  switch (item.procesatorPlati) {
    case "SelfPay":
      return "#ffc600"
    case "PayPoint":
      return "#efd40a"
    case "Un-doi":
      return "#23999a"
    default:
      return "#CD2027"
  }
}

const markersIcons = {
  payPoint: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Vector_Smart_Object_copy_16"
      data-name="Vector Smart Object copy 16"
      width="42.002"
      height="44"
      viewBox="0 0 42.002 44"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stop-color="#ffd500" />
          <stop offset="1" stop-color="#fab304" />
        </linearGradient>
      </defs>
      <path
        id="Ellipse_3_copy"
        data-name="Ellipse 3 copy"
        d="M29.018,34c9.389,0,14.921,1.73,12.357,3.864S17.006,44,17,44c-.032,0-12.19-4-9.625-6.136S19.63,34,29.018,34Z"
        fill="#2d2456"
        opacity="0.102"
      />
      <path
        id="Ellipse_3"
        data-name="Ellipse 3"
        d="M17,0A17,17,0,0,1,34,17c0,9.389-16.994,27-17,27C16.968,44,0,26.389,0,17A17,17,0,0,1,17,0Z"
        fill="url(#linear-gradient)"
      />
      <g
        id="Vector_Smart_Object"
        data-name="Vector Smart Object"
        transform="translate(-117.837 -79.35)"
      >
        <g id="Group_112" data-name="Group 112">
          <path
            id="Path_324"
            data-name="Path 324"
            d="M136,93.5h-1.3v2.045h0V93.5H136a1,1,0,0,1,.974,1.022h0A1,1,0,0,0,136,93.5Z"
            fill="none"
          />
          <path
            id="Path_325"
            data-name="Path 325"
            d="M136,91.452h-3.256V97.6H136a3.077,3.077,0,0,0,0-6.146Zm.974,3.074A1,1,0,0,1,136,95.549h-1.3V93.5H136a1,1,0,0,1,.974,1.022h0Z"
            fill="#2e2556"
          />
          <path
            id="Path_326"
            data-name="Path 326"
            d="M136,87.35h-7.164v18.066l5.862,2.934V101.7H136a7.183,7.183,0,0,0,0-14.35Zm0,12.3h-3.256v5.209l-1.954-.981V89.4H136a5.13,5.13,0,0,1,0,10.248Z"
            fill="#2e2556"
          />
          <path
            id="Path_327"
            data-name="Path 327"
            d="M136,95.548h0a1,1,0,0,0,.974-1.022h0A1,1,0,0,1,136,95.548Z"
            fill="#f5d40e"
          />
        </g>
      </g>
    </svg>
  ),
  selfPay: "/icons/selfpayMarker.png",
}

export default function ReactGoogleMap({
  searchText,
  selectedZone,
  markers,
  selectedMarker,
  setSelectedMarker,
}) {
  const map = useMap("centreDePlataMap")

  React.useEffect(() => {
    const bounds = getMapBoundingBox(markers)
    if (map != null) map.fitBounds(bounds)
  }, [map, markers])

  React.useEffect(() => {
    console.log(map, selectedZone)
    if (selectedZone != null && map != null) {
      map.fitBounds(selectedZone.bounds)
      map.setZoom(selectedZone.zoom)
    }
  }, [map, selectedZone])
  return (
    <Map
      id="centreDePlataMap"
      className="cdpMap"
      onBoundsChanged={event => {
        console.log(event.map.getBounds())
        console.log(event.map.getZoom())
      }}
      initialBounds={{
        north: 47.421414477884696,
        east: 28.17205191350688,
        south: 45.53599977661273,
        west: 24.83220816350688,
      }}
      // center={{ lat: 22.54992, lng: 0 }}
      // gestureHandling=
      disableDefaultUI={true}
      clickableIcons={false}
      maxZoom={17}
      //   onZoomChanged={event => {
      //     setTimeout(10)
      //   }}
      styles={[
        {
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              color: "#f5f5f5",
            },
          ],
        },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#bdbdbd",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off ",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#757575",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [
            {
              color: "#dadada",
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#616161",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [
            {
              color: "#e5e5e5",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "geometry",
          stylers: [
            {
              color: "#eeeeee",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#c9c9c9",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [
            {
              color: "#9e9e9e",
            },
          ],
        },
      ]}
    >
      {selectedMarker != null && (
        <InfoWindow
          //   pixelOffset={140}
          onCloseClick={() => setSelectedMarker(null)}
          maxWidth={250}
          key={selectedMarker.id}
          position={{
            lat: selectedMarker.latLng[0] + 0.00035,
            lng: selectedMarker.latLng[1],
          }}
        >
          <div
            className={`cdpLCItem infoWindowItem ${getCLItemClassNameFromTipProcesator(
              selectedMarker
            )}`}
          >
            <div className="cdpLCIIcon">
              {getCLIIconClassNameFromTipProcesator(selectedMarker)}
            </div>
            <div className="cdpLCItemTitle">
              {selectedMarker.denumireCentru}
            </div>
            <div className="cdpLCItemAddress">{selectedMarker.adresa}</div>
            {selectedMarker.programLucru && (
              <div className="cdpLCItemProgram">
                <b>Program:</b> {selectedMarker.programLucru}
              </div>
            )}
            <div className="iwDirectiiButton">
              <a
                className="iwDirectiiButtonLink"
                href={`https://www.google.com/maps/dir/?api=1&destination=${selectedMarker.latLng[0]},${selectedMarker.latLng[1]}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Direcții
              </a>
            </div>
          </div>
        </InfoWindow>
      )}
      {markers != null &&
        markers.map((item, index) => (
          <Marker
            // ref={markerRef}
            //   label={item.denumireCentru}
            // icon={markersIcons.selfPay}
            icon={{
              path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
              fillColor: getMarkerIconColorFromTipProcesator(item),
              fillOpacity: 1,
              strokeColor: "#FFF",
              strokeWeight: 1,
              scale: 1.2,
            }}
            position={{ lat: item.latLng[0], lng: item.latLng[1] }}
            onClick={() => {
              setSelectedMarker(item)
              if (map != null) {
                map.setCenter({
                  lat: item.latLng[0],
                  lng: item.latLng[1],
                })
                map.setZoom(16)
              }
            }}
          />
        ))}
    </Map>
  )
}

function getMapBoundingBox(markers) {
  let matBoundingBox = {
    lat: {
      min: 1000,
      max: 0,
    },
    lng: {
      min: 1000,
      max: 0,
    },
  }

  for (const marker of markers) {
    if (marker.latLng[0] < matBoundingBox.lat.min) {
      matBoundingBox.lat.min = marker.latLng[0]
    }
    if (marker.latLng[0] > matBoundingBox.lat.max) {
      matBoundingBox.lat.max = marker.latLng[0]
    }
    if (marker.latLng[1] < matBoundingBox.lng.min) {
      matBoundingBox.lng.min = marker.latLng[1]
    }
    if (marker.latLng[1] > matBoundingBox.lng.max) {
      matBoundingBox.lng.max = marker.latLng[1]
    }
  }

  if (matBoundingBox.lat.min === 1000)
    matBoundingBox.lat.min = 45.53599977661273
  if (matBoundingBox.lat.max === 0) matBoundingBox.lat.max = 47.421414477884696
  if (matBoundingBox.lng.min === 1000)
    matBoundingBox.lng.min = 24.83220816350688
  if (matBoundingBox.lng.max === 0) matBoundingBox.lng.max = 28.17205191350688

  return {
    north: matBoundingBox.lat.max,
    east: matBoundingBox.lng.max,
    south: matBoundingBox.lat.min,
    west: matBoundingBox.lng.min,
  }
}
