import { APIProvider } from "@vis.gl/react-google-maps"
import React from "react"
import { Helmet } from "react-helmet"
import NewSelect from "../../../components/CereriNew/NewSelect"
import Footer from "../../../components/Footer"
import ReactGoogleMap, {
  CentruDePlata,
  getCLIIconClassNameFromTipProcesator,
  getCLItemClassNameFromTipProcesator,
} from "../../../components/GoogleMap"
import SiteMenu from "../../../components/siteMenu"
import { getAllUrlParams } from "../../../utils"
import "./style.css"

const centreDePlataJson: CentruDePlata[] = [
  {
    id: 1,
    procesatorPlati: "SelfPay",
    denumireCentru: "DTL Market",
    adresa: "Strada Constantin Langa, 90, Miroslava",
    latLng: [47.14616514468424, 27.53192906560171],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 2,
    procesatorPlati: "SelfPay",
    denumireCentru: "Family Market Miroslava",
    adresa: "Strada Arhitect Beldiman Filaret 25, Miroslava 707306",
    latLng: [47.145984459822294, 27.528932467443155],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 3,
    procesatorPlati: "SelfPay",
    denumireCentru: "Carrefour  ERA ",
    adresa: " Șoseaua Păcurari 121, Centrul Comercial Era, Iași 700522",
    latLng: [47.166979775316776, 27.513474293214617],
    programLucru: "8:00 - 22:00",
    idZona: 1,
  },
  {
    id: 4,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi Iași Voinești",
    adresa: "Aleea Voinești 46, Iași 707305",
    latLng: [47.147742766495895, 27.54936102872491],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 5,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi Pacurari",
    adresa: "Șoseaua Păcurari 138, Iași 700545",
    latLng: [47.172696176608795, 27.5202038807732],
    programLucru: "6:00 - 0:00",
    idZona: 1,
  },
  {
    id: 6,
    procesatorPlati: "SelfPay",
    denumireCentru: "Paco Supermarket Odobești",
    adresa: "Strada Ștefan cel Mare 32, Odobești 625300",
    latLng: [45.76000646853965, 27.066619339543287],
    programLucru: "7:00 - 22:00",
    idZona: 3,
  },
  {
    id: 7,
    procesatorPlati: "SelfPay",
    denumireCentru: "Paco Supermarket Odobești",
    adresa: "Strada Ștefan cel Mare 40, Odobești 625300",
    latLng: [45.761399024954066, 27.067768353683594],
    programLucru: "6:00 - 22:00",
    idZona: 3,
  },
  {
    id: 8,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi Odobesti ",
    adresa: "Strada Ștefan cel Mare 40, Odobești 625300",
    latLng: [45.760527745539214, 27.066518920835914],
    programLucru: null,
    idZona: 3,
  },
  {
    id: 9,
    procesatorPlati: "SelfPay",
    denumireCentru: "Peco - ODOBESTI",
    adresa: "Strada Erou Nicolae Ioniță 28, Odobești 625300",
    latLng: [45.75762067311237, 27.064649569153993],
    programLucru: null,
    idZona: 3,
  },
  {
    id: 10,
    procesatorPlati: "SelfPay",
    denumireCentru: "Paco Supermarket - JARISTEA",
    adresa: "Strada Inv.Ion Saragea, Jariștea 627180",
    latLng: [45.794490977587266, 27.06037350238595],
    programLucru: "7:00 - 21:00",
    idZona: 3,
  },
  {
    id: 11,
    procesatorPlati: "SelfPay",
    denumireCentru: "Peco – JARISTEA",
    adresa: "Strada Ion Saragea 2, Jariștea 627180",
    latLng: [45.79326724282418, 27.078058414216724],
    programLucru: null,
    idZona: 3,
  },
  {
    id: 12,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi Panciu",
    adresa: "Strada Ciprian Porumbescu, 12",
    latLng: [45.90824711058314, 27.08810069094612],
    programLucru: "non-stop",
    idZona: 2,
  },
  {
    id: 13,
    procesatorPlati: "SelfPay",
    denumireCentru: "Penny PANCIU",
    adresa: "Strada Nicolae Titulescu 62, Panciu 620003",
    latLng: [45.90470961756748, 27.090848115931628],
    programLucru: "8:00 - 21:00",
    idZona: 2,
  },
  {
    id: 14,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image Panciu",
    adresa: "Strada Nicolae Titulescu 58, Panciu",
    latLng: [45.905853740311514, 27.09013958535013],
    programLucru: "7:00 - 22:00",
    idZona: 2,
  },
  {
    id: 15,
    procesatorPlati: "SelfPay",
    denumireCentru: "Munteanu Team",
    adresa: "Strada Grivița 31, Satu Nou",
    latLng: [45.88460738956474, 27.09977932006257],
    programLucru: null,
    idZona: 2,
  },
  {
    id: 16,
    procesatorPlati: "SelfPay",
    denumireCentru: "Ginomar Market",
    adresa: "DN2L, 178 Straoane",
    latLng: [45.93526276228164, 27.04712097950852],
    programLucru: "7:00 - 21:00",
    idZona: 2,
  },
  {
    id: 17,
    procesatorPlati: "SelfPay",
    denumireCentru: "DTL Market",
    adresa: "Strada Sararie, 220, Iași",
    latLng: [47.13630356014393, 27.590177794919992],
    programLucru: "7:00 - 17:00",
    idZona: 1,
  },
  {
    id: 18,
    procesatorPlati: "SelfPay",
    denumireCentru: "Peco Ticau",
    adresa: "Bulevardul Constantin Alexandru Rosetti, 28, Iasi",
    latLng: [47.18261481140677, 27.57114906823779],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 19,
    procesatorPlati: "SelfPay",
    denumireCentru: "CMA Selected",
    adresa: "DC13 20, Cârlig 707381",
    latLng: [47.20923884495059, 27.557420718151267],
    programLucru: "7:00 - 21:00",
    idZona: 1,
  },
  {
    id: 20,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Shop & Go",
    adresa: "Strada Eternitate 3, Bloc 305, Iași 700305",
    latLng: [47.16725882992841, 27.594616948551145],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 21,
    procesatorPlati: "PayPoint",
    denumireCentru: "CMM MARKET - magazin alimentar",
    adresa: "Strada Profesor Dumitru Pricop 18, Vorovești 707319",
    latLng: [47.130444, 27.477267],
    programLucru: "6:00 - 21:00",
    idZona: 1,
  },
  {
    id: 22,
    procesatorPlati: "PayPoint",
    denumireCentru: "Magazinul fără nume (ProTv)",
    adresa: "Strada Principală Ciurbești 36, Județul Iași",
    latLng: [47.098076, 27.530106],
    programLucru: "7:00 - 21:00",
    idZona: 1,
  },
  {
    id: 23,
    procesatorPlati: "PayPoint",
    denumireCentru: "ERA Shopping Parc",
    adresa: "Șoseaua Păcurari 121, Iași",
    latLng: [47.167012, 27.513461],
    programLucru: "10:00 - 21:00",
    idZona: 1,
  },
  {
    id: 24,
    procesatorPlati: "PayPoint",
    denumireCentru: "La Doi Pasi Cornesti",
    adresa: "DC28, Cornești 707309",
    latLng: [47.11046, 27.510942],
    programLucru: "7:00 - 21:00",
    idZona: 1,
  },
  {
    id: 25,
    procesatorPlati: "PayPoint",
    denumireCentru: "Green Market Astoria",
    adresa: "Strada Principală nr 43, Lunca Cetățuii 707085",
    latLng: [47.096417, 27.5496],
    programLucru: "7:00 - 21:00",
    idZona: 1,
  },
  {
    id: 26,
    procesatorPlati: "PayPoint",
    denumireCentru: "Punct Plata Paypoint",
    adresa: "Str. Trandafirilor 11, Valea Lupului 707410",
    latLng: [47.17674153004888, 27.500530347932393],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 27,
    procesatorPlati: "PayPoint",
    denumireCentru: "DTL Market",
    adresa: "Șos. Nicolina 1A, Iași 700007",
    latLng: [47.14193298654707, 27.580378318552377],
    programLucru: "non-stop",
    idZona: 1,
  },
  {
    id: 28,
    procesatorPlati: "PayPoint",
    denumireCentru: "Paco Supermarket",
    adresa: "Strada Ștefan cel Mare 40, Odobești 625300",
    latLng: [45.761403956664545, 27.0677690832766],
    programLucru: "6:00 - 22:00",
    idZona: 3,
  },
  {
    id: 29,
    procesatorPlati: "PayPoint",
    denumireCentru: "Paco Supermarket",
    adresa: "Strada Ștefan cel Mare nr.32, Odobești 625300",
    latLng: [45.75999008004842, 27.066618310417546],
    programLucru: "6:00 - 22:00",
    idZona: 3,
  },
  {
    id: 30,
    procesatorPlati: "PayPoint",
    denumireCentru: "Magazin Mixt",
    adresa: "STR STEFAN CEL MARE NR 5, Odobesti",
    latLng: [45.75664276011879, 27.063471446089842],
    programLucru: null,
    idZona: 3,
  },
  {
    id: 31,
    procesatorPlati: "PayPoint",
    denumireCentru: "Magazin 1001 Articole",
    adresa: "STR STEFAN CEL MARE NR 4, Odobesti",
    latLng: [45.75651043219277, 27.063670589969206],
    programLucru: null,
    idZona: 3,
  },
  {
    id: 32,
    procesatorPlati: "PayPoint",
    denumireCentru: "Profi Campineanca",
    adresa: "Calea odobești 311",
    latLng: [45.711682, 27.126793],
    programLucru: "7:00 - 21:00",
    idZona: 2,
  },
  {
    id: 33,
    procesatorPlati: "PayPoint",
    denumireCentru: "Magazin La 2 Lei",
    adresa: "Strada Hotarului 60, Cîmpineanca 627055",
    latLng: [45.70835210066769, 27.138161121877545],
    programLucru: "7:00 - 22:00",
    idZona: 2,
  },
  {
    id: 34,
    procesatorPlati: "PayPoint",
    denumireCentru: "Magazin Mixt",
    adresa: "Str. Principală Nr. 145, Chirnogi 917025",
    latLng: [44.113339, 26.570341],
    programLucru: "6:00 - 21:00",
    idZona: 4,
  },
  {
    id: 35,
    procesatorPlati: "PayPoint",
    denumireCentru: "Profi Chirnogi",
    adresa: "Str. Viilor 2, Chirnogi 917025",
    latLng: [44.106726039455424, 26.570967524372858],
    programLucru: null,
    idZona: 4,
  },
  {
    id: 36,
    procesatorPlati: "Un-doi",
    denumireCentru: "Magazin Mixt",
    adresa: "Centura Iași 55, Uricani 707316",
    latLng: [47.156305, 27.486739],
    programLucru: "6:00 - 22:00",
    idZona: 1,
  },
  {
    id: 37,
    procesatorPlati: "Un-doi",
    denumireCentru: "Magazin Mixt",
    adresa: "Strada Cicoarei 42, Iași 707305",
    latLng: [47.155231809443045, 27.51784679577562],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 38,
    procesatorPlati: "Un-doi",
    denumireCentru: "Magazin Mixt",
    adresa: "Strada Malduri, Brătuleni 707307",
    latLng: [47.16669105616843, 27.460595547911026],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 39,
    procesatorPlati: "Un-doi",
    denumireCentru: "Magazin Mixt",
    adresa: "DC28 28, Proselnici 707314",
    latLng: [47.109014558146626, 27.49135298200779],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 40,
    procesatorPlati: "Un-doi",
    denumireCentru: "La Doi Pasi Cornesti",
    adresa: "DC28, Cornești 707309",
    latLng: [47.11046, 27.510942],
    programLucru: "7:00 - 21:00",
    idZona: 1,
  },
  {
    id: 41,
    procesatorPlati: "Un-doi",
    denumireCentru: "Magazinul fără nume (ProTv)",
    adresa: "Strada Principală Ciurbești 36, Județul Iași",
    latLng: [47.098076, 27.530106],
    programLucru: "7:00 - 21:00",
    idZona: 1,
  },
  {
    id: 42,
    procesatorPlati: "Un-doi",
    denumireCentru: "Green Market Astoria",
    adresa: "Strada Principală nr 43, Lunca Cetățuii 707085",
    latLng: [47.096417, 27.5496],
    programLucru: "7:00 - 21:00",
    idZona: 1,
  },
  {
    id: 43,
    procesatorPlati: "Un-doi",
    denumireCentru: "Libraria Nicolina",
    adresa: "Șos. Nicolina 11, Iași",
    latLng: [47.13822815149853, 27.577696338047176],
    programLucru: "8:00 - 18:00",
    idZona: 1,
  },
  {
    id: 44,
    procesatorPlati: "SelfPay",
    denumireCentru: "Magazin SUN CONSTRUCT",
    adresa: "Strada Constantin Mavrocordat Voda, 15",
    latLng: [47.141552, 27.528912],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 45,
    procesatorPlati: "SelfPay",
    denumireCentru: "Tienda Image srl",
    adresa: "Intrarea Caisilor, 2",
    latLng: [44.118645, 26.576868],
    programLucru: null,
    idZona: 4,
  },
  {
    id: 46,
    procesatorPlati: "SelfPay",
    denumireCentru: "Val Cris Food",
    adresa: "DC27, 25",
    latLng: [44.118664, 26.577162],
    programLucru: null,
    idZona: 4,
  },
  {
    id: 47,
    procesatorPlati: "SelfPay",
    denumireCentru: "NOOR ANSARI (BENZINARIE RO OSCAR)",
    adresa: "Strada Nicoriță 26, Iași 700397",
    latLng: [47.15940172042458, 27.601445647903176],
    programLucru: "non-stop",
    idZona: 1,
  },
  {
    id: 48,
    procesatorPlati: "SelfPay",
    denumireCentru: "CARREFOUR MARKET Iulius Mall (Artima)",
    adresa: "Bulevardul Tudor Vladimirescu, 113",
    latLng: [47.155075, 27.60553],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 49,
    procesatorPlati: "SelfPay",
    denumireCentru: "Benzinaria SOCAR",
    adresa: "Str. Splai Bahlui, nr. 32",
    latLng: [47.15376, 27.603207],
    programLucru: "non-stop",
    idZona: 1,
  },
  {
    id: 50,
    procesatorPlati: "SelfPay",
    denumireCentru: "Carrefour Express",
    adresa: "Strada Tătărași nr 73-75, Iași 700259",
    latLng: [47.163074, 27.60517],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 51,
    procesatorPlati: "SelfPay",
    denumireCentru: "KAUFLAND",
    adresa: "Bulevardul Chimiei 2D, Iași 700291",
    latLng: [47.152428, 27.605532],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 52,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi City",
    adresa: "Bulevardul Tudor Vladimirescu 22, Iași 707337",
    latLng: [47.164093, 27.596178],
    programLucru: "6:00 - 22:00",
    idZona: 1,
  },
  {
    id: 53,
    procesatorPlati: "SelfPay",
    denumireCentru: "CARREFOUR MARKET",
    adresa: "Strada Anastasie Panu",
    latLng: [47.160767, 27.591034],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 54,
    procesatorPlati: "SelfPay",
    denumireCentru: "CEC Bank",
    adresa: "Strada Anastasie Panu, 42",
    latLng: [47.16165, 27.59148],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 55,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image Newton",
    adresa: "Strada Vasile Lupu 78",
    latLng: [47.158948769223365, 27.609949179348902],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 56,
    procesatorPlati: "SelfPay",
    denumireCentru: "Marytour",
    adresa: "Bulevardul Primăverii 19, Iași 700171",
    latLng: [47.151134, 27.596632],
    programLucru: "8:00 - 18:00",
    idZona: 1,
  },
  {
    id: 57,
    procesatorPlati: "SelfPay",
    denumireCentru: "CEC Bank",
    adresa: "Bloc D1, Strada Vasile Lupu 83, Iași 700319",
    latLng: [47.16036, 27.61316],
    programLucru: "8:00 - 18:00",
    idZona: 1,
  },
  {
    id: 58,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi City",
    adresa: "Strada Stejar nr. 13, Iași 700327",
    latLng: [47.16268, 27.61194],
    programLucru: "non-stop",
    idZona: 1,
  },
  {
    id: 59,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi Standard",
    adresa: "Strada Han Tătar 6, Iași 700319",
    latLng: [47.159073, 27.613617],
    programLucru: "7:00 - 12:00",
    idZona: 1,
  },
  {
    id: 60,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image",
    adresa: "Piața Palat 1, Iași 700019",
    latLng: [47.15993819260739, 27.58797397985407],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 61,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi City",
    adresa: "Bulevardul Independenței 19-25, Iași 700102",
    latLng: [47.166413659743434, 27.587623951888176],
    programLucru: "non-stop",
    idZona: 1,
  },
  {
    id: 62,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image",
    adresa: "Bulevardul Independenței 33, Iași",
    latLng: [47.16587336313678, 27.58974884169743],
    programLucru: "6:00 - 12:00",
    idZona: 1,
  },
  {
    id: 63,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi City",
    adresa: "Bulevardul Chimiei 35, Iași 707252",
    latLng: [47.152424, 27.617632],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 64,
    procesatorPlati: "SelfPay",
    denumireCentru: "CEC Bank",
    adresa: "Bulevardul Socola 16, Iași 700194",
    latLng: [47.147675238794754, 27.59124966274596],
    programLucru: "9:00 - 19:00",
    idZona: 1,
  },
  {
    id: 65,
    procesatorPlati: "SelfPay",
    denumireCentru: "Dedeman",
    adresa: "Bulevardul Primăverii nr. 2, Iași 700264",
    latLng: [47.146360934775274, 27.595069815472833],
    programLucru: "8:00 - 21:00",
    idZona: 1,
  },
  {
    id: 66,
    procesatorPlati: "SelfPay",
    denumireCentru: "La Doi Pasi ",
    adresa: "Soseaua Nationala, 194",
    latLng: [47.15202, 27.58434],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 67,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image",
    adresa: "Bulevardul Stefan cel Mare si Sfant, 9",
    latLng: [47.16269, 27.582525],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 68,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image",
    adresa: "Strada Vasile Lupu 152, Iași 707252",
    latLng: [47.153172292608986, 27.621969998169813],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 69,
    procesatorPlati: "SelfPay",
    denumireCentru: "PECO",
    adresa: "Bulevardul Constantin Alexandru Rosetti, 28",
    latLng: [47.171864, 27.591274],
    programLucru: "non-stop",
    idZona: 1,
  },
  {
    id: 70,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi City",
    adresa: "Bulevardul Dimitrie Cantemir 7, Iași 700233",
    latLng: [47.15124, 27.580793],
    programLucru: "non-stop",
    idZona: 1,
  },
  {
    id: 71,
    procesatorPlati: "SelfPay",
    denumireCentru: "Carrefour Market",
    adresa: "Str. Ciurchi, Iași 700359",
    latLng: [47.15525096060274, 27.626426033409324],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 72,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image",
    adresa: "Bulevardul Socola 57, Iași",
    latLng: [47.14131176805647, 27.600790313442946],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 73,
    procesatorPlati: "SelfPay",
    denumireCentru: "Kaufland",
    adresa: "Str. Pavlov, Nr. 14, Jud. Iasi",
    latLng: [47.161217, 27.575907],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 74,
    procesatorPlati: "SelfPay",
    denumireCentru: "Kaufland",
    adresa: "Strada Mitropolit Varlaam 20, Iași 700198",
    latLng: [47.143443377605955, 27.587338573913282],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 75,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image",
    adresa: "Bulevardul Nicolae Iorga 37, Iași 700211",
    latLng: [47.14989675641815, 27.57877278328784],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 76,
    procesatorPlati: "SelfPay",
    denumireCentru: "Benzinaria MOL",
    adresa: "Strada Silvestru, Iași 700012",
    latLng: [47.15939, 27.57424],
    programLucru: "non-stop",
    idZona: 1,
  },
  {
    id: 77,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image",
    adresa: "Strada Lacului 16, Iași 700704",
    latLng: [47.1427455988393, 27.582692002455214],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 78,
    procesatorPlati: "SelfPay",
    denumireCentru: "Supermarket ZERCATO",
    adresa: "Strada Dimineții 15, Iași",
    latLng: [47.13887849797753, 27.591767318773815],
    programLucru: "8:00 - 21:00",
    idZona: 1,
  },
  {
    id: 79,
    procesatorPlati: "SelfPay",
    denumireCentru: "PENNY",
    adresa: "Strada Pantelimon Halipa, nr. 12A",
    latLng: [47.15024, 27.57305],
    programLucru: "8:00 - 22:00",
    idZona: 1,
  },
  {
    id: 80,
    procesatorPlati: "SelfPay",
    denumireCentru: "Benzinaria SOCAR",
    adresa: "Bulevardul Poitiers, Nr. 2",
    latLng: [47.1374397576297, 27.604972069114343],
    programLucru: "non-stop",
    idZona: 1,
  },
  {
    id: 81,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image",
    adresa: "Strada Pantelimon Halipa, 4A",
    latLng: [47.153290761354974, 27.570858334989875],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 82,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi City",
    adresa: "Strada Anton Crihan 8/10, Iași 700623",
    latLng: [47.1538270404085, 27.569124260386666],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 83,
    procesatorPlati: "PayPoint",
    denumireCentru: "TLT - Totul langa tine",
    adresa: "Voinești 707600",
    latLng: [47.07273288783032, 27.431741689040464],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 84,
    procesatorPlati: "PayPoint",
    denumireCentru: "Magazin Mixt",
    adresa: "Cornesti",
    latLng: [47.10971362730506, 27.510957439918485],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 85,
    procesatorPlati: "PayPoint",
    denumireCentru: "La Razvanel",
    adresa: "DC17 20, Aroneanu 707023",
    latLng: [47.2032018937277, 27.6029137717789],
    programLucru: "7:00 - 21:00",
    idZona: 5,
  },
  {
    id: 86,
    procesatorPlati: "PayPoint",
    denumireCentru: "Supermarket",
    adresa: "Șoseaua Voinești, Iasi 707600",
    latLng: [47.075565278545874, 27.420182321927232],
    programLucru: "6:00 - 22:00",
    idZona: 1,
  },
  {
    id: 87,
    procesatorPlati: "PayPoint",
    denumireCentru: "Magazin Mixt",
    adresa: "Str. Gospodarilor 15, Chirnogi 917025",
    latLng: [44.10462258258832, 26.58496397426035],
    programLucru: null,
    idZona: 4,
  },
  {
    id: 88,
    procesatorPlati: "PayPoint",
    denumireCentru: "Magazin Mixt",
    adresa: "STR CALEA ODOBESTI 306",
    latLng: [45.71004439014656, 27.127481606873513],
    programLucru: null,
    idZona: 2,
  },
  {
    id: 89,
    procesatorPlati: "Un-doi",
    denumireCentru: "Magazin Cena",
    adresa: "Str. Viilor 14, Chirnogi 917025",
    latLng: [44.10774821560169, 26.567863595183134],
    programLucru: null,
    idZona: 4,
  },
  {
    id: 90,
    procesatorPlati: "Un-doi",
    denumireCentru: "Magazin La Salcii",
    adresa: "Str. Principală 45b, Horpaz 707313",
    latLng: [47.105448147343324, 27.551295589690373],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 93,
    procesatorPlati: "Un-doi",
    denumireCentru: "La doi Pasi? Mega Image? Chilipirul zilei?",
    adresa: "str. principala nr. 27",
    latLng: [47.13595148910882, 27.49185768605262],
    programLucru: null,
    idZona: 1,
  },
  {
    id: 94,
    procesatorPlati: "Un-doi",
    denumireCentru: "La Doi Pasi",
    adresa: "Str. Aron Voda 51, Aroneanu",
    latLng: [47.20390907433213, 27.603984618084734],
    programLucru: null,
    idZona: 5,
  },
  {
    id: 95,
    procesatorPlati: "SelfPay",
    denumireCentru: "Magazin mixt",
    adresa: "Strada Sf Gheorghe, Dorobanț",
    latLng: [47.223568, 27.594555],
    programLucru: "7:00 - 22:00",
    idZona: 5,
  },
  {
    id: 96,
    procesatorPlati: "SelfPay",
    denumireCentru: "Profi City",
    adresa: "Str. Nouă nr. 115, Horpaz 707313",
    latLng: [47.101784, 27.541025],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
  {
    id: 97,
    procesatorPlati: "SelfPay",
    denumireCentru: "Mega Image",
    adresa: "DJ248A 14, Valea Ursului 707318",
    latLng: [47.13675816578616, 27.491429542348026],
    programLucru: "7:00 - 22:00",
    idZona: 1,
  },
]

const zoneHarta = [
  {
    id: 1,
    denumire: "Miroslava",
    bounds: {
      south: 47.086655195130334,
      west: 27.37388770489542,
      north: 47.242115552095676,
      east: 27.615243600891514,
    },
    zoom: 12,
  },
  {
    id: 2,
    denumire: "Panciu",
    bounds: {
      south: 45.86761113255696,
      west: 27.023671556227082,
      north: 45.94716178597991,
      east: 27.14434950422513,
    },
    zoom: 13,
  },
  {
    id: 3,
    denumire: "Odobești",
    bounds: {
      south: 45.69546168849864,
      west: 27.044806607186633,
      north: 45.775258515518686,
      east: 27.16548455518468,
    },
    zoom: 13,
  },
  {
    id: 4,
    denumire: "Chirnogi",
    bounds: {
      south: 44.138042091644216,
      west: 26.801458461670904,
      north: 44.30191007067581,
      east: 27.042814357666998,
    },
    zoom: 12,
  },
]

export default function CentreDePlata({ location, defaultIndex = 0 }) {
  // console.log("Rendering contact page")
  const [selectedMarker, setSelectedMarker] =
    React.useState<CentruDePlata | null>(null)
  const [centreDePlata, setCentreDePlata] = React.useState<CentruDePlata[]>(
    centreDePlataJson.filter(item => item.idZona === zoneHarta[defaultIndex].id)
  )
  const [selectedZoneIndex, setSelectedZoneIndex] =
    React.useState<number>(defaultIndex)
  const [searchText, setSearchText] = React.useState("")

  // React.useEffect(() => {
  // if (selectedMarker != null) setSearchText(selectedMarker.denumireCentru)
  // else setSearchText("")
  // }, [selectedMarker])

  React.useEffect(() => {
    if (selectedZoneIndex > -1) {
      setCentreDePlata(
        centreDePlataJson.filter(
          item => item.idZona === zoneHarta[selectedZoneIndex].id
        )
      )
    }
  }, [selectedZoneIndex])

  React.useEffect(() => {
    const { zona } = getAllUrlParams(location.search)
    console.log(zona)
    //Q:How to check zona with chirilic characters?

    let indexZona = zoneHarta.findIndex(item => item.denumire === zona)
    if (zona === "Odobe%C8%99ti") indexZona = 2
    if (indexZona == -1) indexZona = 0
    setSelectedZoneIndex(indexZona)
  }, [location])

  React.useEffect(() => {
    if (selectedMarker) {
      // check if is on mobile device
      if (window.innerWidth < 768) {
        const element = document.getElementById(`centreDePlataMap`)
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          })
        }
      } else {
        const element = document.getElementById(
          `cdpLCItem-${selectedMarker.id}`
        )
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          })
        }
      }
    }
  }, [selectedMarker])

  return [
    <div key={1} id="pageCentreDePlata" className="whitePage">
      {/* @ts-ignore */}
      <Helmet>
        <title>Centre de plată - Gazmir</title>
        <link rel="canonical" href="https://gazmir.ro/contact" />
        <meta
          name="description"
          content="Poți plăti la unul din centrele afișate pe hartă. Gazmir aduce căldura caminului tau!"
        />
        <meta
          name="keywords"
          content="Contact gazmir,Gazmir contact,Gazmir,Informatii gazmir,Gazmir informatii,Date contact gazmir,Adresa gazmir,Gazmir adresa,sediu Gazmir,Gazmir sediu,gazmir locatie,Caserie gazmir,Gazmir caserie,ghiseu gazmir"
        />
      </Helmet>
      <SiteMenu location={location} showPaymentRedirectLoading={false} />
      <div className="pageContent">
        <div className="pageRow">
          <div className="pageColumn">
            <div className="pageTitle">Centre de plată</div>
            <div className="pageSubtitle">
              Găsește cel mai apropiat centru de plată
            </div>
          </div>
        </div>

        <div className="pageRow cdpMapContainer">
          <div className="searchAndInfoContainer whiteBoxWithShadow">
            <div className="cdpInfoRow ">
              {/* <FormInput
              className="cdpSearchInput bgColorGrayBackground colorDarkAccent darkAccentPlaceholder"
              placeholder="Caută"
              label="Cauta"
              value={searchText}
              onChange={(value, name) => setSearchText(value)}
              isRequired={false}
            /> */}

              <NewSelect
                selectLabel="Selectează localitate"
                selectClassName="selectLocalitate"
                items={zoneHarta}
                selectedIndex={selectedZoneIndex}
                selectIndex={setSelectedZoneIndex}
                noSelectionText="Selectează localitate"
              />
            </div>
            <div className="cdpListCentre">
              {centreDePlata
                .filter(
                  item =>
                    item.adresa
                      .toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    item.denumireCentru
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                )
                .map(item => (
                  <div
                    id={`cdpLCItem-${item.id}`}
                    key={item.id}
                    className={`cdpLCItem ${getCLItemClassNameFromTipProcesator(
                      item
                    )} ${item.id === selectedMarker?.id ? "selected" : ""}`}
                    onClick={() => setSelectedMarker(item)}
                  >
                    <div className="cdpLCIFirstRow">
                      <div className="cdpLCIIcon">
                        {getCLIIconClassNameFromTipProcesator(item)}
                      </div>
                      <div className="cdpLCIItemText">
                        <div className="cdpLCItemTitle">
                          {item.denumireCentru.toLowerCase()}
                        </div>
                        <div className="cdpLCItemAddress">
                          {item.adresa.toLowerCase()}
                        </div>
                        {item.programLucru && (
                          <div className="cdpLCItemProgram">
                            <b>Program:</b> {item.programLucru}
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          "cdpLCIItemExtendIcon " +
                          (item.id === selectedMarker?.id ? " extended" : "")
                        }
                      >
                        <svg
                          viewBox="0 0 9 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.818115 9.63622L2.99989 11.818L8.81796 5.99992L2.99989 0.181854L0.818115 2.36363L4.45441 5.99992L0.818115 9.63622Z"
                            fill="#0D3345"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      className={
                        "cdpLCISecondRow " +
                        (item.id === selectedMarker?.id ? " extended" : "")
                      }
                    >
                      <div className="cdpLCItemAddress">{item.adresa}</div>
                      {item.programLucru && (
                        <div className="cdpLCItemProgram">
                          <b>Program:</b> {item.programLucru}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <APIProvider
            apiKey={"AIzaSyAWhjGFAmARGDd2eIMFTOP06TIalgVm6Qo"}
            authReferrerPolicy="origin"
            region="RO"
          >
            <ReactGoogleMap
              selectedZone={zoneHarta[selectedZoneIndex]}
              selectedMarker={selectedMarker}
              setSelectedMarker={setSelectedMarker}
              searchText={searchText}
              markers={centreDePlata}
            />
          </APIProvider>
        </div>
      </div>
    </div>,
    <Footer key={2} containerClassName="homeFooter" />,
  ]
}
