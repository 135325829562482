import React, { useState } from "react"
import { colors } from "../../styles/globals"

type PropTypes = {
  selectedIndex: number | null
  selectIndex: (index: number) => void
  items: { id: number; denumire: string }[]
  noSelectionText: string
  selectLabel?: string
  selectClassName?: string
}

export default function NewSelect({
  selectedIndex,
  selectIndex,
  items,
  noSelectionText = "Alege",
  selectClassName = "",
  selectLabel,
}: PropTypes) {
  const [showSelect, setShowSelect] = useState(false)

  const toggleSelect = () => {
    setShowSelect(oldValue => !oldValue)
  }

  return [
    selectLabel != null ? (
      <div key={1} className="selectLabel">
        {selectLabel}
      </div>
    ) : null,
    <div
      key={2}
      className={"newSelect " + selectClassName}
      onClick={items.length > 1 ? toggleSelect : undefined}
      onMouseLeave={() => setShowSelect(false)}
    >
      <div className="selectedOption">
        {selectedIndex === null
          ? noSelectionText
          : items[selectedIndex].denumire}
        {items.length > 1 && (
          <span className="selectArrow">
            <svg
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.18178 0L0 2.18178L5.81807 7.99985L11.6361 2.18178L9.45436 0L5.81807 3.63629L2.18178 0Z"
                fill="white"
              />
            </svg>
          </span>
        )}
      </div>

      {showSelect && (
        <div className="selectOptionsContainer">
          {items.map((item, index) =>
            index === selectedIndex ? null : (
              <div
                className="selectOption"
                key={index}
                onClick={() => selectIndex(index)}
              >
                {item.denumire}
              </div>
            )
          )}
        </div>
      )}
    </div>,
  ]
}
